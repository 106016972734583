import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { withStyles, createStyles, } from '@material-ui/core/styles';

import { withQuoteWizard } from '../components/withQuoteWizard';

import { NewsTipsPage } from '../imageURLs';

import SEO from '../components/SEO';

import CtaCard from '../components/CtaCard/CtaCard';
import TextBlock from '../components/TextBlock';
import CenterContent from '../components/CenterContent';

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up(960)]: {
        paddingTop: 30,
        justifyContent: 'center',
      },
    },
  });

const ThePage = ({ data, classes, children }) => (
  <React.Fragment>
    <SEO
      title="News and Tips by Twin View Turf - Producer of Australia’s Best Lawns"
      keywords={[
        `Turf`,
        `Lawns`,
        `Best Lawns`,
        `Australia’s Best Lawns`,
        `lawn producer`,
      ]}
    />

    <TextBlock text="News and Tips" />
 
    <Grid container direction="column" className={classes.root}>
      <Grid container item>
        <CenterContent style={{ maxWidth: 960 }}>
          <CtaCard
            image={NewsTipsPage.freshBest}
            header="5 reasons why fresh is best"
            text="Same day harvest makes all the difference for your new lawn."
            ctaHref="/news-and-tips/fresh-is-best/"
            ctaText="Read more"
            cardType="article"
          />
          <CtaCard
            image={NewsTipsPage.rainNotEnough}
            header="Why rain isn’t always enough"
            text="When it rains, it doesn't always pour – so don't rely on the sky!"
            ctaHref="/news-and-tips/rain-not-enough/"
            ctaText="Read more"
            cardType="article"
          />
        </CenterContent>
      </Grid>

      <Grid container item>
        <CenterContent style={{ maxWidth: 960 }}>
          <CtaCard
            image={NewsTipsPage.fertiliseTiming}
            header="When should I fertilise?"
            text="Overfertilising your lawn will create more work, but not enough will leave it suffering – so, how often and when?"
            ctaHref="/news-and-tips/fertilise/"
            ctaText="Read more"
            cardType="article"
          />
          <Hidden smDown>
            <Grid container item md={6}>
              {/* for 3 articles to align well */}
            </Grid>
          </Hidden>
        </CenterContent>
      </Grid>
    </Grid>

    {children}
  </React.Fragment>
);

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage)
);
